.scanner-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Asegura que los clics pasen al escáner */
}

.scanner-area {
  width: 70%; /* Ajusta el tamaño según tus necesidades */
  height: 50%; /* Ajusta el tamaño según tus necesidades */
  border: 2px dashed rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
  pointer-events: none; /* Asegura que los clics no interfieran con el escáner */
}
