.pam{
	background: #b6b6b6 !important;
}
.pam_minus{
	background: #cfcfcf !important;
}

.pcd{
	background: #9fceff !important;
}
.pcd_minus{
	background: #d2e8ff !important;
}

.vivienda,.viviendas{
    background-color: rgb(243, 234, 253) !important;
}



.clase-rojo-1 {
    background-color: #ff0000 !important; /* Rojo muy intenso */
}

.clase-rojo-2 {
    background-color: #ff3333 !important; /* Rojo claro */
}

.clase-rojo-3 {
    background-color: #ff6666 !important; /* Rojo suave */
}

.clase-amarillo-1 {
    background-color: #ffff00 !important; /* Amarillo brillante */
}

.clase-amarillo-2 {
    background-color: #ffff33 !important; /* Amarillo claro */
}

.clase-amarillo-3 {
    background-color: #ffff66 !important; /* Amarillo suave */
}

.clase-verde-1 {
    background-color: #66ff66 !important; /* Verde suave */
}

.clase-verde-2 {
    background-color: #33ff33 !important; /* Verde claro */
}

.clase-verde-3 {
    background-color: #00ff00 !important; /* Verde brillante */
}

.clase-verde-4 {
    background-color: #009900 !important; /* Verde oscuro */
}



.color-empty {
  fill: #ebedf0;
}

.color-github-1 {
  fill: #c6e48b;
}

.color-github-2 {
  fill: #7bc96f;
}

.color-github-3 {
  fill: #239a3b;
}

.color-github-4 {
  fill: #196127;
}








.react-calendar-heatmap .color-empty {
  fill: #ebedf0;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685; /* Muy bajo */
}

.react-calendar-heatmap .color-scale-2 {
  fill: #8cc665; /* Bajo */
}

.react-calendar-heatmap .color-scale-3 {
  fill: #44a340; /* Moderado */
}

.react-calendar-heatmap .color-scale-4 {
  fill: #1e6823; /* Alto */
}



/* Aplica un borde solo a las columnas que no son la última */
.col-with-divider:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, .4); /* Puedes cambiar el color y grosor del borde */
}




.col-with-divider {
  padding-bottom: 10px;
}




// Definir clases para los colores
.percentage-0-10 {
  background: rgb(255, 0, 0) !important; // Rojo
}

.percentage-10-30 {
  background: rgb(255, 165, 0) !important; // Naranja
}

.percentage-30-50 {
  background: rgb(255, 255, 0) !important; // Amarillo
}

.percentage-50-70 {
  background: rgb(173, 255, 47) !important; // Verde claro
}

.percentage-70-90 {
  background: rgb(0, 128, 0) !important; // Verde
}

.percentage-90-100 {
  background: rgb(0, 255, 0) !important; // Verde brillante
}
