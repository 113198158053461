.ROLE_ADMIN{
	background: #f0f0f0 !important;
}
.ROL_REGION{
	background: #8bc34a !important;
}
.ROL_MICROREGION,.ROL_MUNICIPAL{
	background: #4a8bc3 !important;
}
.ROL_ZONA{
	background: #c34a8b !important;
}
.ROL_AREA{
	background: #dfae5c !important;
}


