.trabajo_poco {
  background-color: #ffb4b4 !important;
}

.trabajo_mediano {
  background-color: #ffff9a !important;
}

.trabajo_bien {
  background-color: #ccff9a !important;
}

.trabajo_excelente {
  background-color: #8dff1a !important;
}

.trabajo_goat {
  background-color: #cc9aff !important;
}
