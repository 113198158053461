$theme: "journal" !default;

//
// Color system
//


  .leaflet-container {
    height: 400px;
    width: 100%;
    margin: 0 auto;
  }

@import './colors.scss';



$min-contrast-ratio:   1.28 !default;

// Fonts

// $headings-font-family:   "News Cycle", "Arial Narrow Bold", sans-serif !default;
// $headings-font-weight:    700 !default;
// $headings-line-height:    1.1 !default;

// Buttons

$input-btn-padding-x:         1rem !default;

// Navbar

$navbar-light-color:                rgba($tema, .7) !default;
$navbar-light-hover-color:          $tema !default;
$navbar-light-active-color:         $tema !default;

$navbar-brand-font-size:            10;

// Pagination

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $primary !default;
$pagination-hover-border-color:     $primary !default;


@import '~bootstrap/scss/bootstrap.scss';
@import "~fontawesome-4.7/scss/font-awesome.scss";


.contenedor{
  border:1px solid #dee2e6;
  margin-top:10px;
  padding:10px;
}




// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(./fonts/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(./fonts/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(./fonts/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }



// @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

// ----------------------------------------------------------------------

@font-face {
    font-family: 'Barlow';
    src: url('./fonts/Barlow-Black.ttf') format('truetype');
    font-weight: 900; /* Para Barlow Black */
}

@font-face {
    font-family: 'Barlow';
    src: url('./fonts/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: 200; /* Para Barlow ExtraLight */
}

@font-face {
    font-family: 'Barlow';
    src: url('./fonts/Barlow-Regular.ttf') format('truetype');
    font-weight: 400; /* Para Barlow Regular */
}



/* Carga la fuente Gilroy ExtraBold */
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('./fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 800; /* Puedes ajustar el valor según la fuente real */
  font-style: normal;
}

/* Carga la fuente Gilroy Light */
@font-face {
  font-family: 'Gilroy-Light';
  src: url('./fonts//Gilroy-Light.otf') format('opentype');
  font-weight: 300; /* Puedes ajustar el valor según la fuente real */
  font-style: normal;
}




h1,h2,h3,h4,h5,h6,b{
  // font-family: 'Gilroy-ExtraBold', sans-serif;
  // font-family: 'Roboto', sans-serif;
  font-family: 'Barlow';
  font-weight: 900;
}

a, button{
    // font-family: 'Gilroy-Light', sans-serif!important;
    font-family: 'Barlow';
    font-weight: 400;
  // font-family: 'Suez One', serif;
}

p, body, html, small, a{
  // font-family: 'Roboto', sans-serif !important;
  // font-family: 'Slabo 27px', serif;
    // font-family: 'Gilroy-Light', sans-serif!important;
    font-family: 'Barlow';
}

b, label, .b{
  font-weight: 900;
  // font-family: 'Gilroy-ExtraBold', sans-serif !important;
  font-family: 'Barlow';
  // font-family: 'DM Serif Text';
}





// ----------------------------------------------------------------------



@media screen and (max-width: 768px) {
    .navbar {
        margin-bottom: 20px !important;
    }
}








.asignar_contrato{
  background: $danger !important;
  color: white !important;
}







.cancelado{
  background: #dc6c6c !important;
  color: white !important;
}

.finalizado{
  background: #b0c6e4 !important;
  color: white !important;
}

.card{
  margin-top: 0 !important;
}





.obligatorios {
    color: #c81212;
    font-size: 10px;
    vertical-align: top;
}






// Navbar

.bg-dark {
  background-color: $tema !important;
}

.bg-light {
  background-color: $white !important;
  color: $tema;
  border: 1px solid $gray-200;

  &.navbar-fixed-top {
    border-width: 0 0 1px;
  }

  &.navbar-fixed-bottom {
    border-width: 1px 0 0;
  }
}





.navbar {
    // flex-wrap: wrap !important;
    // padding: 0px 10px !important;
    // justify-content: space-between !important;
    margin-bottom:25px;
}

label {
  display: block;
  // margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.nm{
  margin:0 !important;
}


.v{
  vertical-align: middle;
}


.vat{
  vertical-align: top;
}

.pointer{
  cursor:pointer;
}



.loader { 
  width:100%; 
  margin:0 auto;
  border-radius:0px;
  border:4px solid transparent;
  position:relative;
  padding:1px;
}
.loader:before {
  content:'';
  border:1px solid #e0e0e0; 
  border-radius:0px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:0px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#e0e0e0; 
  width:0;
  animation:borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}


.text-left{
  text-align:left;
}

.text-right{
  text-align:right;
}

.text-center{
  text-align:center;
}




.card{
  padding:0 !important;
}


.bg-facebook{
  background: #2374e1 !important;
}


.bg-instagram{
  background: #d9307f !important;
}



.bg-twitter{
  background: #1ca1f2 !important;
}

.bg-youtube{
  background: #ff0000 !important;
}



hr.style-six {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}




.form-label{
  font-weight: bold;
}

@media screen and (max-width:768px) {

   .navbar{
    margin-bottom: 0;
  }

  .padding_mobil{
    padding: 15px 15px 0 15px;
  }

}


@media screen and (min-width:768px) {

  .onlymobile{
    display: none;
  }

}




table.minimalistBlack {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.minimalistBlack td, table.minimalistBlack th {
  border: 1px solid #C4C4C4;
  padding: 5px 4px;
}
table.minimalistBlack tbody td {
  font-size: 13px;
}
table.minimalistBlack thead {
    border-bottom: 2px solid #B9B9B9;
}
table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table.minimalistBlack tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #CFCFCF;
}
table.minimalistBlack tfoot td {
  font-size: 14px;
}







.estatus_1, .estatus_atencion_1{
  background: #007BFF !important;
}
.estatus_2, .estatus_atencion_2{
  // background: #4CAF50 !important;
  color : black !important;
  background: var(--bs-yellow) !important;
  box-shadow: inset 0 0 12px #f88d1d !important;

}
.estatus_3, .estatus_atencion_3{
  background: #FFC107 !important;
}
.estatus_4, .estatus_atencion_4{
  background: #f58100 !important;
  // color:white;
}
.estatus_5, .estatus_atencion_5{
  background: #FF9800 !important;
}
.estatus_7, .estatus_atencion_7{
  background: #47aa2a !important;
  color: white;
}
.estatus_9, .estatus_atencion_6,.estatus_atencion_9{
  background: #F44336 !important;
  // color:white;
}

.estatus_atencion_99{
  background: #F44336 !important;
  // color:white;
}






$custom-orange : #ffa500;
$custom-mantenimiento : rgb(4, 128, 190);


.btn-orange {
  background-color: $custom-orange;
  border-color: $custom-orange;
  color:white;
  &:hover {
    background-color: darken($custom-orange, 10%);
    border-color: darken($custom-orange, 10%);
  }
  &:active {
    background-color: darken($custom-orange, 20%);
    border-color: darken($custom-orange, 20%);
  }
}

.btn-tema {
  background-color: $tema;
  border-color: $tema;
  color:white;
  &:hover {
    background-color: darken($tema, 10%);
    border-color: darken($tema, 10%);
  }
  &:active {
    background-color: darken($tema, 20%);
    border-color: darken($tema, 20%);
  }
}


$custom-subs : #0084b1;
.table-subs{
  background-color: $custom-subs !important;
  border-color: $custom-subs !important;
  color:white;
}

$custom-impuestos : #006789;
.table-impuestos{
  background-color: $custom-impuestos !important;
  border-color: $custom-impuestos !important;
  color:white;
}

$custom-total : #002c3b;
.table-total{
  background-color: $custom-total !important;
  border-color: $custom-total !important;
  color:white;
}




.btn-mantenimiento {
  background-color: $custom-mantenimiento;
  color:white;
  border-color: $custom-mantenimiento;
  &:hover {
    background-color: darken($custom-mantenimiento, 10%);
    border-color: darken($custom-mantenimiento, 10%);
    color:white;
  }
  &:active {
    background-color: darken($custom-mantenimiento, 20%);
    border-color: darken($custom-mantenimiento, 20%);
  }
}


$mantenimiento : #cde5ff;
$conservacion : #fff5e7;
$limpieza : #b5dfb7;



  // if("PRESUPUESTO_CONSERVACION"===tipo) return "CONSERVACIÓN" 
  // if("PRESUPUESTO_REPARACION"===tipo) return "REPARACION" 
  // if("PRESUPUESTO_INSTALACION"===tipo) return "INSTALACIÓN" 
  // if("PRESUPUESTO_SUMINISTRO"===tipo) return "SUMINISTRO" 
  // if("PRESUPUESTO_SUMINISTRO_INSTALACION"===tipo) return "SUMINISTRO E INSTALACION" 

.PRESUPUESTO_CONSERVACION{
  background: #006400 !important;
  color: white;
}
.PRESUPUESTO_REPARACION{
  background: #FFA500 !important;
}
.PRESUPUESTO_INSTALACION{
  background: #87CEEB !important;
}
.PRESUPUESTO_LIMPIEZA{
  background: #6fdfbe !important;
}
.PRESUPUESTO_SUMINISTRO{
  background: #00FF7F !important;
}
.PRESUPUESTO_SUMINISTRO_INSTALACION{
  background: grey !important;
}
.GASTO_OPERACION{
  background: #3498db !important;
}
.GASTOS_MOVILIZACION{
  background: #a52a2a !important;
  color: white;
}

.POR_DEFINIR{
  background: #6f42c1 !important;
  color: white;
}

.PRESUPUESTO_INSUMOS{
  background: orange !important;
  color: white;
}



// '','MANTENIMIENTO POR CONTRATO'
.tipo_orden_1,{
  background: $mantenimiento !important;
  color: black;
  padding: 4px 15px;
}
// '','SERVICIO DE MANTENIMIENTO'
.tipo_orden_2{
  background: $mantenimiento !important;
  color: black;
  padding: 4px 15px;
}
// '','SERVICIO DE MANTENIMIENTO DE EMERGENCIA'
.tipo_orden_3{
  background: $mantenimiento !important;
  color: black;
  padding: 4px 15px;
}
// '','CONSERVACIÓN POR CONTRATO'
.tipo_orden_4{
  background: $conservacion !important;
  color: black;
  padding: 4px 15px;
}
// '','SERVICIO DE CONSERVACIÓN'
.tipo_orden_5{
  background: $conservacion !important;
  color: black;
  padding: 4px 15px;
}
// '','SERVICIO DE CONSERVACIÓN EMERGENCIA'
.tipo_orden_6{
  background: $conservacion !important;
  color: black;
  padding: 4px 15px;
}
// '','SERVICIO DE LIMPIEZA'
.tipo_orden_7{
  background: $limpieza !important;
  color: black;
  padding: 4px 15px;
}
// '','MANTENIMIENTO DÍA EXTRA'
.tipo_orden_8{
  background: red !important;
  color: black;
  padding: 4px 15px;
}
// '','OTRO'
.tipo_orden_9{
  background: red !important;
  color: black;
  padding: 4px 15px;
}


.tipo_orden_10,.tipo_orden_11{
  background: #e9ffea !important;
  color: black;
  padding: 4px 15px;
}



.tipo_orden_refaccionamiento,.tipo_orden_extra{
  background: #000 !important;
  color: #fff;
  padding: 4px 15px;
}








// '','NUEVO'
.estatus_ticket_1{
  background: #d4ebf2 !important;
}
// '','EN ATENCIÓN'
.estatus_ticket_2{
  background: #ffffeb !important;
}
// '','ATENDIDO'
.estatus_ticket_3{
  background: #afcfaf !important;
}
// '','CERRADO'
.estatus_ticket_4{
  background: #000000 !important;
  color:white;
}






.estatus_presupuesto_1{
  background: #fffbd8 !important;
  // background: #ADD8E6;
}


.estatus_presupuesto_2{
  background: #e5f6fe !important;
}


.estatus_presupuesto_3{
  background: #fff7eb !important;
}


.estatus_presupuesto_4{
  background: #f0f0f0 !important;
}


.estatus_presupuesto_5{
  background: #e1f2e2 !important;
}


.estatus_presupuesto_6{
  background: #f3f9ec !important;
}

.estatus_presupuesto_7{
  background: #f3f9ec !important;
}

.estatus_presupuesto_8{
  background: #fde0dd !important;
}


.estatus_presupuesto_9{
  background: #fde0dd !important;
}

 


.delgadita{
  // font-family: 'Gilroy-Light' !important;
  font-family: 'Barlow' !important;
}





.REPORTE_MANTENIMIENTO{
  background: $mantenimiento !important;
}

.REPORTE_CONSERVACION{
  background: $conservacion !important;
}



.chiquitita{
  font-size: 10px;
  min-height: 0;
}









.gallery-item {
  margin-bottom: 20px; // Espacio entre las imágenes
}

// Establece una altura máxima para las imágenes
.gallery-item img {
  max-height: 200px; // Ajusta la altura máxima deseada
  width: auto; // Para mantener la proporción de aspecto
}










hr.style-one {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
}









.select-invalid .select__control {
  border-color: red;
}





.calificacion_servicio_1{
    background: red;
    padding: 2px 5px;
    color: white;
}

.calificacion_servicio_2{
    background: #f57a00;
    padding: 2px 5px;
    color: white;
}

.calificacion_servicio_3{
    background: #808080;
    padding: 2px 5px;
    color: white;
}

.calificacion_servicio_4{
    background: #3788d8;
    padding: 2px 5px;
    color: white;
}

.calificacion_servicio_5{
    background: #336699;
    padding: 2px 5px;
    color: white;
}



.table-row-disabled {
  background-color: #f5f5f5 !important;  /* Gris claro para indicar deshabilitado */
  color: #d6d6d6;  /* Texto gris */
  pointer-events: none;  /* Desactiva interacciones del ratón en la fila */
  opacity: 0.6;  /* Hace que la fila se vea más apagada */
  
  td {
    color: #d6d6d6;  /* Asegura que todas las celdas dentro del row también estén deshabilitadas */
  }

  /* Deshabilitar todos los botones por defecto */
  button {
    pointer-events: none;  /* Deshabilita interacciones del ratón en los botones */
    opacity: 0.5;  /* Estilo visual para los botones deshabilitados */
  }

  /* El botón que estará activo debe tener un estilo diferente */
  .btn-funcional {
    pointer-events: auto;  /* Habilita la interacción solo para este botón */
    opacity: 1;  /* Vuelve al 100% de opacidad para el botón activo */
  }
}