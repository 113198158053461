.adulto{
	background: #e3e3e3 !important;
}

.discapacidad{
	background: #d2e8ff !important;
}

.H{
	background: #add8e6 !important;
}

.M{
	background: #ffb6c1 !important;
}




.folio_cancelado{
	background:  red !important;
	color: #ffffff !important;
}


.react-datepicker, .react-datepicker__month-container {
  width: 100% !important; // Asegúrate de que ocupe todo el ancho
}