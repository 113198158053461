/* Ajuste para que el contenedor principal ocupe todo el alto de la ventana menos el navbar */
.main-container {
  height: calc(100vh - 100px); /* Ajusta el tamaño del contenedor restando el alto del navbar */
  // padding-top: 256px; /* Espacio para el navbar */
}

.left-panel {
  // background-color: #f8f9fa; /* Fondo claro para los controles */
  height: 100%; /* Ocupa el 100% del alto disponible */
  overflow-y: auto; /* Scroll en la columna izquierda si el contenido excede */
  padding: 0 0px; /* Añade espacio interior */
}

.right-panel {
  padding: 0; /* Sin padding para que el mapa ocupe todo el espacio */
  height: 100%; /* Ocupa el 100% del alto disponible */
}

.map-container {
  height: 100%; /* El mapa debe ocupar todo el alto de su contenedor */
  width: 100%; /* El mapa ocupa todo el ancho disponible */
  // background-color: #e9ecef; /* Color de fondo temporal, reemplazar con tu mapa */
}







